import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import MaintenancePage from '../ui/pages/MaintenancePage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const Maintenance = props => (
  <>
    <HelmetComponent
      title={metaTags.maintenanceTitle}
      description={metaTags.maintenance}
      page="de/maintenance"
    />
    <AppWrapper {...props} lang="de">
      <MaintenancePage />
    </AppWrapper>
  </>
)

export default Maintenance
